/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "amplifyIdentityBrokerApi",
            "endpoint": "https://flp833v123.execute-api.us-east-1.amazonaws.com/preprodenv",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:61aff2eb-bfbe-4584-951b-df6925f61291",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fLm3nlF4Y",
    "aws_user_pools_web_client_id": "9fn90ivekrcgv4gft0grltho3",
    "oauth": {
        "domain": "wave-identity-broker-preprodenv.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://d2avh47q2byz9p.cloudfront.net",
        "redirectSignOut": "https://d2avh47q2byz9p.cloudfront.net/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "broker--hostingbucket-preprodenv",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2avh47q2byz9p.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "amplifyIdentityBrokerClients-preprodenv",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerCodes-preprodenv",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerInstance-preprodenv",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerRegistrationCodes-preprodenv",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerUser-preprodenv",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerUserInstance-preprodenv",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
